import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TicketBookImage from "../../content/assets/Disney-ticket-book.jpg"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="The Unofficial Walt Disney Blog" />
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '96vh' }}>
        <h1>The [Unofficial] Walt Disney Blog</h1>
        <p>
          Your ticket to the best Disney events, shopping, and dining
          experiences
        </p>
        <img src={TicketBookImage} alt="Disney Ticket Book Image" />
        <p>Coming later 2020, unless something Goofy happens!</p>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
